import React from 'react'
import styled from 'styled-components'
import { Center } from '../layout'
import { AboutPageSettings } from '../../types/aboutPageSettings'

interface Props {
  aboutPageSettings: AboutPageSettings
  topRightImage: JSX.Element | null
  bottomLeftImage: JSX.Element | null
  pressListImages: JSX.Element[] | []
}

export const AboutPageTemplate = ({ aboutPageSettings, topRightImage, bottomLeftImage, pressListImages }: Props) => {
  return (
    <Center>
      <Profile>
        <div className={'left'}>
          <ColorCard align={'left'} color={aboutPageSettings.profileBackgroundColor}>
            <h2>{aboutPageSettings.profileTitle}</h2>
            <div dangerouslySetInnerHTML={{ __html: aboutPageSettings.profile }} />
          </ColorCard>
          <div>{bottomLeftImage}</div>
        </div>
        <div className={'right'}>
          <div>{topRightImage}</div>
          <ColorCard align={'right'} color={aboutPageSettings.skillsBackgroundColor}>
            <h2>{aboutPageSettings.skillsTitle}</h2>
            <ul>
              {aboutPageSettings.skills.map((skill) => (
                <li key={skill.skillName}>{skill.skillName}</li>
              ))}
            </ul>
          </ColorCard>
        </div>
      </Profile>
      <Resume>
        <h2>{aboutPageSettings.resumeTitle}</h2>
        <ul>
          {aboutPageSettings.resumeHistory.map((history) => (
            <JobCard key={history.jobTitle}>
              <div className={'left'}>
                <h3>{history.jobTitle}</h3>
                <p>
                  {history.jobStart}
                  {` - `}
                  {history.jobEnd}
                </p>
              </div>
              <div className={'right'} dangerouslySetInnerHTML={{ __html: history.jobDescription }} />
            </JobCard>
          ))}
        </ul>
      </Resume>
      <Press>
        <h2>{aboutPageSettings.pressTitle}</h2>
        <PressList>
          {pressListImages.map((n: JSX.Element, i: number) => (
            <PressCard key={i}>{n}</PressCard>
          ))}
        </PressList>
      </Press>
    </Center>
  )
}

const Profile = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-template-rows: max-content max-content;
  grid-column-gap: 25px;

  margin-bottom: 80px;

  > div > div {
    margin: 20px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr;

    div.right {
      margin-top: -20px;
    }
  }
`

const Resume = styled.div`
  h2 {
    text-align: center;
    color: ${({ theme }) => theme.color.darkGrey};
    font-weight: ${({ theme }) => theme.font.weight['300']};
    font-size: ${({ theme }) => theme.font.size.xl};
    margin-bottom: 40px;
  }
`

const JobCard = styled.li`
  display: flex;
  align-items: center;
  padding: 40px 0;

  h3 {
    color: ${({ theme }) => theme.color.darkGrey};
    font-weight: ${({ theme }) => theme.font.weight['400']};
    font-size: ${({ theme }) => theme.font.size.l};
  }

  div.left {
    flex: 1;
    padding: 0 20px 0 10px;

    p {
      color: #67a395;
    }
  }

  div.right {
    flex: 3;
    padding: 0 10px;
    color: ${({ theme }) => theme.color.grey};
    font-weight: ${({ theme }) => theme.font.weight['300']};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    div.left {
      text-align: center;
      margin-bottom: 10px;
    }
  }
`

const Press = styled.div`
  margin-top: 50px;

  h2 {
    text-align: center;
    color: ${({ theme }) => theme.color.darkGrey};
    font-weight: ${({ theme }) => theme.font.weight['300']};
    font-size: ${({ theme }) => theme.font.size.xl};
    margin-bottom: 60px;
  }
`

const PressList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const PressCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  > p {
    margin-top: 15px;
  }

  *:first-child {
    max-height: 250px;
    width: 100%;
  }

  * {
    object-fit: contain !important;
  }
`

const ColorCard = styled.div<any>`
  text-align: ${(props) => props.align};
  background-color: ${(props) => props.color};
  padding: ${(props) => (props.align === 'left' ? `15% 10%` : `4% 10%`)};

  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.color.darkGrey};

  h2 {
    font-weight: ${({ theme }) => theme.font.weight['300']};
    margin-bottom: 30px;
  }

  > div,
  > ul {
    font-weight: ${({ theme }) => theme.font.weight['300']};
  }
`
