import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { BasicTemplate } from '../components/templates/basicTemplate'
import { AboutPageTemplate } from '../components/templates/aboutPageTemplate'
import { useBasicSettings } from '../hooks/useBasicSettings'
import { useAboutPageSettings } from '../hooks/useAboutPageSettings'
import { getImageFileName } from '../utils/getImageFileName'

const AboutPage = () => {
  const { basicSettings } = useBasicSettings()
  const { aboutPageSettings } = useAboutPageSettings()

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { regex: "/(jpg)|(png)/" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              placeholder: BLURRED
              quality: 95
              width: 561
              webpOptions: { quality: 95 }
            )
          }
        }
      }
    }
  `)

  const topRightImageName: string = getImageFileName(aboutPageSettings.topRightImage)
  const bottomLeftImageName: string = getImageFileName(aboutPageSettings.bottomLeftImage)
  const pressListImageOrder: string[] = aboutPageSettings.pressList.map((press) => getImageFileName(press.pressImage))

  let topRightImageData: IGatsbyImageData | undefined
  let bottomLeftImageData: IGatsbyImageData | undefined
  const pressListImageData: { [key: string]: IGatsbyImageData } = {}

  data.allFile.nodes.forEach((n: any) => {
    if (n.relativePath === topRightImageName) {
      const img = getImage(n)
      if (img) topRightImageData = img
    }

    if (n.relativePath === bottomLeftImageName) {
      const img = getImage(n)
      if (img) bottomLeftImageData = img
    }

    if (pressListImageOrder.includes(n.relativePath)) {
      const img = getImage(n)
      if (img) pressListImageData[n.relativePath] = img
    }
  })

  const topRightImage = topRightImageData ? <GatsbyImage image={topRightImageData} alt={'portrait'} /> : null
  const bottomLeftImage = bottomLeftImageData ? <GatsbyImage image={bottomLeftImageData} alt={'portrait'} /> : null
  const pressListImages = pressListImageOrder.map((name: string, i: number) => (
    <>
      <GatsbyImage image={pressListImageData[name]} alt={'press'} objectFit={'contain'} />
      <p>{aboutPageSettings.pressList[i].pressName}</p>
    </>
  ))

  return (
    <BasicTemplate basicSettings={basicSettings}>
      <AboutPageTemplate
        aboutPageSettings={aboutPageSettings}
        topRightImage={topRightImage}
        bottomLeftImage={bottomLeftImage}
        pressListImages={pressListImages}
      />
    </BasicTemplate>
  )
}

export default AboutPage
