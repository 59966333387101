import { useStaticQuery, graphql } from 'gatsby'
import { AboutPageSettings } from '../types/aboutPageSettings'

export const useAboutPageSettings = (): { aboutPageSettings: AboutPageSettings } => {
  const data = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/aboutPage.md/" }) {
          frontmatter {
            topRightImage
            bottomLeftImage
            profileTitle
            profile
            profileBackgroundColor
            skillsTitle
            skills {
              skillName
            }
            skillsBackgroundColor
            resumeTitle
            resumeHistory {
              jobDescription
              jobEnd
              jobStart
              jobTitle
            }
            pressTitle
            pressList {
              pressImage
              pressName
            }
          }
        }
      }
    `
  )

  return { aboutPageSettings: data.markdownRemark.frontmatter }
}
